<script setup lang="ts">
import debounce from 'lodash/debounce'
import SelectField from './SelectField.vue';

import { ALL, type SelectOptionType } from '@/types'

const props = defineProps<{
  id: string,
  value?: string,
  query?: string,
}>()

defineEmits(['input'])

const { getProvince } = usePlace()

const provinceQuery = ref('')
const loading = ref(false)
const provinceList = ref<SelectOptionType[]>([])

watch(() => props.query, (value) => {
  if (value === undefined) {
    return
  }
  provinceQuery.value = value
}, { immediate: true })

if (process.client) {
  const updateProvinceList = debounce(async (value: string): Promise<void> => {
    loading.value = true
    provinceList.value = [
      { id: ALL, label: 'Tutte', value: ALL },
      ...await getProvince(value),
    ]
    loading.value = false
  }, 300)

  watch(
    () => provinceQuery.value,
    (value) => {
      if (value.length > 2) {
        updateProvinceList(value)
      }
    },
    { immediate: true },
  )
}
</script>

<template>
  <SelectField :id="id" :value="value" :options="provinceList" :loading="loading" @input="$emit('input', $event)"
    @input:query="provinceQuery = $event">
    <template #label>
      <slot name="label">Provincia</slot>
    </template>
  </SelectField>
</template>
